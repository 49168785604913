@import "../../styles/variables";

.label {
	font-size: 14px;
	line-height: 18px;
	font-family: $font-heading;
	margin-bottom: 5px;
	display: flex;
	align-items: baseline;
}

.subLabel {
	font-family: $font-light;
	font-size: 12px;
	line-height: 12px;
}

.label > div:first-of-type {
	margin-right: 10px;
}

.error {
	color: $color-red;
}

@include large() {
	.label {
		font-size: 16px;
		margin-bottom: 8px;
	}

	.subLabel {
		font-size: 14px;
		line-height: 14px;
		white-space: nowrap;
	}
}