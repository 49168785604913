// Colors
$color-white: #ffffff;
$color-grey: #d8d8d8;
$color-lightgrey: #f7f8f8;
$color-lime: #63d481;
$color-malachite: #00ce5f;
$color-tea-green: #c6e7c7;
$color-indigo: #004346;
$color-spearmint: #c1eecd;
$color-spearmint-light: #ecfaf0;
$color-off-green: #dfebe4;
$color-off-blue: #b2c6c7;
$color-red: #e60202;
$color-black: #000000;
$color-error: #e60202;
$color-grey-50: #F5F5F5;

// Typography
$font-heading: "engrezbold", sans-serif;
$font-subheading: "engrezmedium", sans-serif;
$font-regular: "engrezregular", sans-serif;
$font-light: "engrezlight", sans-serif;

// Breakpoints
@mixin large() {
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        @content;
    }
}

@mixin xlarge() {
    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        @content;
    }
}
